<template>
    <div v-if="currentRouteName && currentRouteName !== 'Login'" :class="['app-container', getSidebarOpen ? 'sidebar-open' : null]">
        <ad-blocker v-if="!adBlock"/>
        <div v-else-if="!getConstants || !getInsightsConstants">
            <loader/>
        </div>
        <div v-else-if="getConstants && getInsightsConstants">
            <Overlay/>
            <Sidebar/>
            <AppDetailHeader/>
            <AppInClientDetailHeader/>
            <ClientDetailHeader/>
            <UserDetailHeader/>
            <UsersListHeader/>
            <TeamsListHeader/>
            <TeamDetailHeader/>
            <ScriptsListHeader/>
            <ScriptDetailHeader/>
            <AppsListHeader/>
            <ClientsListHeader/>
            <CredentialsHeader/>
            <AppsClientListHeader/>
            <AllAppsInClientListHeader/>
            <AllAppsInClientDetailHeader/>
            <DataSourcesListHeader/>
            <DataSharesListHeader/>
            <DataShareDetailHeader/>
            <MetricsListHeader/>
            <MetricDetailHeader/>
            <DimensionsListHeader/>
            <DimensionDetailHeader/>
            <DataSourceDetailHeader/>
            <router-view/>
            <Footer/>
            <Modal/>
            <div v-if="someDrawerOpen() || getSavingObject || modalOpen || getLoading"
                 id="ap2-overlay"
                 class="show">
            </div>
            <div v-if="getLoading" class="loader page-loader" id="preload"></div>
            <FilterClientDrawer/>
            <DrawerAppInClientSummary/>
            <ScriptsDrawer/>
            <AccountVariablesDrawer/>
            <AppFilterDrawer/>
            <DataSourceDrawer/>
            <DatasharesDrawer/>
            <FilterDatasource/>
            <FilterMetrics/>
            <FilterDimensionsDrawer/>
            <FilterDataSourcesDrawer/>
            <AccountsDrawer/>
            <AppInClientAccountsDrawer/>
            <TeamsDrawer/>
            <TeamVariablesDrawer/>
            <UsersDrawer/>
            <AppInClientAccountGroupCampaignsDrawer/>
            <AppInClientAccountGroupCampaignsAdGroupsDrawer/>
            <AppInClientAccountManageGroupsDrawer/>
            <AccountAlertsDrawer/>
        </div>
    </div>
    <div v-else-if="currentRouteName === 'Login'" class="app-container login-page ">
        <ad-blocker v-if="!adBlock"/>
        <router-view v-else-if="getConstants && getInsightsConstants"/>
    </div>
</template>

<script>

import Sidebar from '@/components/commons/sidebar';
import Footer from '@/components/commons/footer'
import Modal from '@/components/modal/Modal'
import Loader from '@/components/commons/loader'
import Overlay from "@/components/commons/overlay";
import {mapGetters} from 'vuex'
import AdBlocker from "@/components/commons/ad-blocker";
import {detectAnyAdblocker} from "vue-adblock-detector";
import {getConstantsData} from "@/services/constants";
import FilterClientDrawer from "@/components/commons/filter-drawer/filter-client/index.vue";
import DrawerAppInClientSummary from "@/pages/apps-in-client/list/app-in-client-summary";
import FilterDatasource from "@/components/commons/filter-drawer/filter-datasource-datashares/index.vue";
import FilterMetrics from "@/components/commons/filter-drawer/filter-metrics/index.vue";
import FilterDimensionsDrawer from "@/components/commons/filter-drawer/filter-dimensions/index.vue";
import FilterDataSourcesDrawer from "@/components/commons/filter-drawer/filter-datasources/index.vue";
import ScriptsDrawer from "@/pages/apps-system/detail/app-page-drawers/scripts-drawer/index.vue";
import AccountVariablesDrawer from "@/pages/apps-system/detail/app-page-drawers/account-variables-drawer";
import AppDetailHeader from "@/components/commons/header/detail-header/app-detail-header";
import AppInClientDetailHeader from "@/components/commons/header/detail-header/app-in-client-detail-header";
import ClientDetailHeader from "@/components/commons/header/detail-header/client-detail-header";
import AppsListHeader from "@/components/commons/header/list-header/apps-list-header";
import ClientsListHeader from "@/components/commons/header/list-header/clients-list-header";
import AppFilterDrawer from "@/components/commons/filter-drawer/filter-apps";
import AccountsDrawer from "@/pages/clients/detail/accounts/accounts-drawer/index.vue";
import AppInClientAccountsDrawer from "@/pages/apps-in-client/detail/accounts-drawer/index.vue";
import UserDetailHeader from "@/components/commons/header/detail-header/user-detail-header/index.vue";
import UsersListHeader from "@/components/commons/header/list-header/users-list-header/index.vue";
import TeamsDrawer from "@/pages/users/detail/teams/teams-drawer/index.vue";
import TeamDetailHeader from "@/components/commons/header/detail-header/team-detail-header/index.vue";
import TeamsListHeader from "@/components/commons/header/list-header/teams-list-header/index.vue";
import TeamVariablesDrawer from "@/pages/teams/detail/variables/variables-drawer/index.vue";
import CredentialsHeader from "@/components/commons/header/credential-detail-header/index.vue";
import ScriptsListHeader from "@/components/commons/header/list-header/scripts-list-header";
import ScriptDetailHeader from "@/components/commons/header/detail-header/script-detail-header";
import UsersDrawer from "@/pages/teams/detail/users/users-drawer/index.vue";
import AppInClientAccountGroupCampaignsDrawer
    from "@/pages/apps-in-client/detail/app-in-client-accounts/app-in-client-account-groups/campaigns-drawer";
import AppInClientAccountGroupCampaignsAdGroupsDrawer
    from "@/pages/apps-in-client/detail/app-in-client-accounts/app-in-client-account-groups/campaign-card/ad-groups-drawer";
import AppInClientAccountManageGroupsDrawer
    from "@/pages/apps-in-client/detail/app-in-client-accounts/manage-groups-drawer";
import AppsClientListHeader from "@/components/commons/header/list-header/apps-client-list-header/index.vue";
import AllAppsInClientListHeader from "@/components/commons/header/list-header/all-apps-in-client-header/index.vue";
import AllAppsInClientDetailHeader
    from "@/components/commons/header/list-header/all-apps-in-client-header/index.vue";
import DataSourcesListHeader from "@/components/commons/header/list-header/data-source-list-header/index.vue";
import DataSourceDetailHeader from "@/components/commons/header/detail-header/data-source-detail-header/index.vue";
import MetricsListHeader from "@/components/commons/header/list-header/metrics-list-header/index.vue";
import MetricDetailHeader from "@/components/commons/header/detail-header/metric-detail-header/index.vue";
import DimensionsListHeader from "@/components/commons/header/list-header/dimensions-list-header/index.vue";
import DataSharesListHeader from "@/components/commons/header/list-header/data-share-list-header/index.vue";
import DataShareDetailHeader from "@/components/commons/header/detail-header/data-share-detail-header/index.vue";
import {getInsightsConstantsData} from "@/services/insights-constants";
import DataSourceDrawer from "@/pages/data-shares/detail/data-source/data-source-drawer/index.vue";
import {getDrawerDimensions} from "@/store/getters";
import {getDrawerMetrics} from "@/store/getters";
import DimensionDetailHeader from "@/components/commons/header/detail-header/dimension-detail-header/index.vue";
import DatasharesDrawer from "@/components/commons/datashares_drawer/index.vue";
import AccountAlertsDrawer from "@/pages/clients/detail/accounts/alerts/alerts-drawer/index.vue";


export default {
    name: 'Index',
    components: {
        AccountAlertsDrawer,
        DatasharesDrawer,
        DimensionDetailHeader,
        DataSourceDrawer,
        DataShareDetailHeader,
        DataSharesListHeader,
        DimensionsListHeader,
        MetricsListHeader,
        MetricDetailHeader,
        DataSourceDetailHeader,
        DataSourcesListHeader,
        FilterDatasource,
        FilterMetrics,
        FilterDimensionsDrawer,
        FilterDataSourcesDrawer,
        AppsClientListHeader,
        AllAppsInClientListHeader,
        AllAppsInClientDetailHeader,
        AppInClientAccountGroupCampaignsAdGroupsDrawer,
        AppInClientAccountGroupCampaignsDrawer,
        AppInClientAccountManageGroupsDrawer,
        UsersDrawer,
        ScriptDetailHeader,
        ScriptsListHeader,
        CredentialsHeader,
        TeamVariablesDrawer,
        TeamsListHeader,
        TeamDetailHeader,
        TeamsDrawer,
        UserDetailHeader,
        AppFilterDrawer,
        ClientDetailHeader,
        AppDetailHeader,
        AppInClientDetailHeader,
        ClientsListHeader,
        AppsListHeader,
        UsersListHeader,
        AccountsDrawer,
        AppInClientAccountsDrawer,
        DrawerAppInClientSummary,
        AccountVariablesDrawer,
        Overlay,
        FilterClientDrawer,
        ScriptsDrawer,
        Sidebar,
        AdBlocker,
        Modal,
        Footer,
        Loader
    },
    computed: {
        ...mapGetters(['getMessage', 'getLoading', 'getConstants', 'getSidebarOpen', 'getClientsDrawerOpen',
            'getDrawerTeams', 'getModalOverwrite', 'getDrawerAppPage', 'getDrawerSummary', 'getDrawerClientAccounts',
            'getSavingObject', 'getDrawerTeamVariables', 'getModalMarketplace', 'getModalConfirmation', 'getModalConfirmationBackward',
            'getDrawerUsers', 'getDrawerAppInClientAccounts', 'getDrawerGroupCampaigns', 'getDrawerCampaignAdGroups',
            'getFilterDrawer', 'getDrawerDataSource', 'getDrawerManageGroups', 'getModalPlatforms', 'getModalDataShareType', 'getModalAccounts',
            'getModalFieldsGoogleSheets', 'getModalFieldsLookerStudio', 'getInsightsConstants', 'getDrawerDimensions', 'getDrawerMetrics', 'getDrawerDataShares', 'getModalConfirmationDataSource']),
        currentRouteName() {
            return this.$route.name;
        },
        modalOpen() {
            return this.getModalOverwrite.show
                    || this.getModalMarketplace.show
                    || this.getModalConfirmation.show
                    || this.getModalConfirmationBackward.show
                    || this.getModalPlatforms.show
                    || this.getModalDataShareType.show
                    || this.getModalAccounts.show
                    || this.getModalConfirmationDataSource.show
                    || this.getModalFieldsGoogleSheets.show
                    || this.getModalFieldsLookerStudio.show;
        }
    },
    mounted() {
        this.getBackendConstants();
        this.getInsightsBackendConstants();
    },
    data() {
        return {
            show: false,
            adBlock: true
        }
    },
    watch: {
        '$route': {
            handler(newValue, oldValue) {
                if (newValue && oldValue) {
                    detectAnyAdblocker().then((detected) => {
                        if (detected) {
                            this.adBlock = false;
                        }
                    });
                }
            },
            deep: true
        }
    },
    methods: {
        async getBackendConstants() {
            let response = await getConstantsData();
            this.$store.dispatch('setConstants', {constants: response.data});
        },
        async getInsightsBackendConstants() {
            let response = await getInsightsConstantsData();
            this.$store.dispatch('setInsightsConstants', {insightsConstants: response.data});
        },
        someDrawerOpen() {
            return this.getClientsDrawerOpen
                    || this.getDrawerSummary.show
                    || this.getDrawerAppPage.show
                    || this.getDrawerClientAccounts.show
                    || this.getDrawerTeams.show
                    || this.getDrawerTeamVariables.show
                    || this.getDrawerUsers.show
                    || this.getDrawerAppInClientAccounts.show
                    || this.getDrawerGroupCampaigns.show
                    || this.getDrawerCampaignAdGroups.show
                    || this.getFilterDrawer.show
                    || this.getDrawerManageGroups.show
                    || this.getDrawerDataSource.show
                    || this.getDrawerDataShares.show
                    || this.getDrawerDimensions.show
                    || this.getDrawerMetrics.show


        },
    },
}
</script>

<style lang="scss">
@import '../assets/scss/app.scss';
</style>
