<template>
    <div :class="['ap2-modal', 'ap2-modal-md', 'ap2-modal-is-drawer', getDrawerAppPage.show ? 'open' : null]"
         id="drawer-test">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                        {{ title }}
                    </div>
                    <div class="modal-header-title">
                        {{ subtitle }}
                    </div>
                </div>
                <div class="modal-header-close close-modal-demo">
                    <svg @click="closeAppPageDrawer()"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content ps">
                <perfect-scrollbar>
                    <div class="form-group ap2-form-group">
                        <div class="ap2-form-control-has-icon left is-search">
                            <input :value="filterItemsText"
                                   @input="filterItemsText=$event.target.value"
                                   class="form-control ap2-form-control"
                                   placeholder="Search"
                                   type="text">
                            <span class="icon" :style="'-webkit-mask-image: url(' + search + ')'"></span>
                        </div>
                    </div>
                    <div v-if="loading" class="text-center">
                        <i class="fas fa-spinner fa-spin"></i> Loading data
                    </div>
                    <ul v-else class="input-selector-list has-visible-input">
                        <li v-for="item in getItemsWithFilter"
                            class="form-label"
                            :key="item.id">
                            <slot name="item"
                                  v-bind:item="item"/>
                        </li>
                    </ul>
                </perfect-scrollbar>
            </div>
            <div class="ap2-modal-footer modal-controls">
                <div class="modal-controls">
                    <button @click="closeAppPageDrawer()"
                            type="button"
                            class="ap2-btn btn btn-dark close-modal-demo">
                        Cancel
                    </button>
                    <button @click="setSelectedItems()"
                            type="button"
                            class="ap2-btn btn btn-success close-modal-demo">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import search from '@/assets/images/magnifying-glass-solid.svg';
    import {PerfectScrollbar} from 'vue3-perfect-scrollbar';

    export default {
        name: "AppPageDrawer",
        props: {
            data: {
                type: Array
            },
            title: {
                type: String
            },
            subtitle: {
                type: String
            }
        },
        components: {PerfectScrollbar},
        computed: {
            ...mapGetters(['getDrawerAppPage', 'getConstants']),
            getItemsWithFilter() {
                let items = [];
                if (this.filterItemsText) {
                    _.forEach(this.data.filter(element => _.toLower(element.name).includes(_.toLower(this.filterItemsText))), item => {
                        items.push(item);
                    })
                } else {
                    _.forEach(this.data, item => {
                        items.push(item);
                    })
                }
                return items
            }
        },
        data() {
            return {
                filterItemsText: null,
                search: search,
                loading: false
            }
        },

        methods: {
            closeAppPageDrawer() {
                this.filterItemsText = null;
                this.$emit('dispatch-close-drawer')
            },
            setSelectedItems() {
                this.filterItemsText = null;
                this.$emit('dispatch-save')
            },
        }
    }
</script>

<style scoped>

</style>