<template>
    <div v-if="getUser"
         @mouseover="openSidebar()"
         @mouseleave="closeSidebar()"
         id="sidebar" :class="isLocalMode ? 'bg-local' : 'bg-prod'"
    >
        <a href="/clients" class="logo-text-no-link">
            <div class="brand">
                <div class="logo-wrapper" :style="'background: url(' + logo + ') no-repeat'"/>
                <div class="brand-name in-expanded-sidebar">THE PLATFORM</div>
            </div>
        </a>
        <ul class="sidebar-elements ap2-scrollbar" id="nav">
            <li class="sidebar-element">
                <div>
                    <a href="/clients" class="sidebar-element-row sidebar-element-link">
                        <!--                    <router-link to="/clients" class="sidebar-element-row sidebar-element-link">-->
                        <div class="d-flex align-items-center" @click="openConfirmationModal($event, '/clients')">
                            <span class="sidebar-element-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path class="svg-fill"
                                          d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/>
                                </svg>
                            </span>
                            <span class="sidebar-element-name in-expanded-sidebar ap2-truncate">
                            Clients
                            </span>
                        </div>
                        <!--                        </router-link>-->
                    </a>
                </div>
            </li>
            <li v-if="isAdminUser || isSystemUser"
                class="sidebar-element">
                <div
                    @click="setManagement()"
                    :class="['sidebar-element-row ap2-toggle-collapse', getManagementOpen ? 'show' : null]"
                    data-ap2-target="#management-options">
                    <span class="sidebar-element-icon">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512 ">
                            <path class="svg-fill"
                                  d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                        </svg>
                    </span>
                    <span class="sidebar-element-name in-expanded-sidebar ap2-truncate">
                        Management
                    </span>
                    <span class="sidebar-element-chevron in-expanded-sidebar">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                     <path class="svg-fill"
                           d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                  </svg>
               </span>
                </div>
                <ul id="management-options" :class="['sidebar-submenu', getManagementOpen ? 'show' : null]">

                    <li>
                        <a href="/users" class="sidebar-element-row sidebar-element-link">
                            <!--                        <router-link to="/users" class="sidebar-element-row sidebar-element-link">-->
                            <span @click="openConfirmationModal($event, '/users')">
                                Users
                            </span>
                            <!--                        </router-link>-->
                        </a>
                    </li>
                    <li v-if="isSystemUser">
                        <a href="/teams" class="sidebar-element-row sidebar-element-link">
                            <!--                        <router-link to="/teams" class="sidebar-element-row sidebar-element-link">-->
                            <span @click="openConfirmationModal($event, '/teams')">
                                Teams
                            </span>
                        </a>
                        <!--                        </router-link>-->
                    </li>
                </ul>
            </li>
            <li v-if="isSystemUser"
                class="sidebar-element">
                <div
                    @click="setSystem()"
                    :class="['sidebar-element-row ap2-toggle-collapse', getSystemOpen ? 'show' : null]">
               <span class="sidebar-element-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                     <path class="svg-fill"
                           d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/>
                  </svg>
               </span>
                    <span class="sidebar-element-name in-expanded-sidebar ap2-truncate">System</span>
                    <span class="sidebar-element-chevron in-expanded-sidebar">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                     <path class="svg-fill"
                           d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                  </svg>
               </span>
                </div>
                <ul id="system-options" :class="['sidebar-submenu', getSystemOpen ? 'show' : null]">

                    <li>
                        <a href="/apps-system/list" class="sidebar-element-row sidebar-element-link">
                            <!--                        <router-link to="/apps-system/list" class="sidebar-element-row sidebar-element-link">-->
                            <span @click="openConfirmationModal($event, '/apps-system/list')">
                                Market
                            </span>
                        </a>
                        <!--                        </router-link>-->
                    </li>
                    <li>

                        <a href="/all-apps-client" class="sidebar-element-row sidebar-element-link">
                            <!--                        <router-link to="/all-apps-client" class="sidebar-element-row sidebar-element-link">-->
                            <span @click="openConfirmationModal($event, '/all-apps-client')">
                                Apps in Client
                            </span>
                        </a>
                        <!--                        </router-link>-->
                    </li>
                    <li>
                        <a href="/scripts" class="sidebar-element-row sidebar-element-link">
                            <!--                        <router-link to="/scripts" class="sidebar-element-row sidebar-element-link">-->
                            <span @click="openConfirmationModal($event, '/scripts')">
                                Scripts
                            </span>
                        </a>
                        <!--                        </router-link>-->
                    </li>
                    <a href="/credentials" class="sidebar-element-row sidebar-element-link">
                        <!--                    <router-link to="/credentials" class="sidebar-element-row sidebar-element-link">-->
                        <li>
                            <span @click="openConfirmationModal($event, '/credentials')">
                                Credentials
                            </span>
                        </li>
                    </a>
                    <!--                    </router-link>-->
                </ul>
            </li>
            <li class="sidebar-element">
                <div
                    @click="setInsights()"
                    :class="['sidebar-element-row ap2-toggle-collapse', getInsightsOpen ? 'show' : null]"
                    data-ap2-target="#management-options">
                    <span class="sidebar-element-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path class="svg-fill"
                                  d="M448 80v48c0 44.2-100.3 80-224 80S0 172.2 0 128V80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6V288c0 44.2-100.3 80-224 80S0 332.2 0 288V186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6V432c0 44.2-100.3 80-224 80S0 476.2 0 432V346.1z"/></svg>
                    </span>
                    <span class="sidebar-element-name in-expanded-sidebar ap2-truncate">
                        Insights
                    </span>
                    <span class="sidebar-element-chevron in-expanded-sidebar">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                     <path class="svg-fill"
                           d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                  </svg>
               </span>
                </div>
                <ul id="management-options" :class="['sidebar-submenu', getInsightsOpen ? 'show' : null]">
                    <li>
                        <a href="/data-sources" class="sidebar-element-row sidebar-element-link">
                            <!--                        <router-link to="/users" class="sidebar-element-row sidebar-element-link">-->
                            <span @click="openConfirmationModal($event, '/data-sources')">
                                Data Sources
                            </span>
                            <!--                        </router-link>-->
                        </a>
                    </li>
                    <li>
                        <a href="/data-shares" class="sidebar-element-row sidebar-element-link">
                            <!--                        <router-link to="/teams" class="sidebar-element-row sidebar-element-link">-->
                            <span @click="openConfirmationModal($event, '/data-shares')">
                                Data Shares
                            </span>
                        </a>
                        <!--                        </router-link>-->
                    </li>
                    <li>
                        <a href="/metrics" class="sidebar-element-row sidebar-element-link">
                            <!--                        <router-link to="/users" class="sidebar-element-row sidebar-element-link">-->
                            <span @click="openConfirmationModal($event, '/metrics')">
                                Metrics
                            </span>
                            <!--                        </router-link>-->
                        </a>
                    </li>
                    <li>
                        <a href="/dimensions" class="sidebar-element-row sidebar-element-link">
                            <!--                        <router-link to="/users" class="sidebar-element-row sidebar-element-link">-->
                            <span @click="openConfirmationModal($event, '/dimensions')">
                                Dimensions
                            </span>
                            <!--                        </router-link>-->
                        </a>
                    </li>
                </ul>
            </li>
            <li v-if="isSystemUser"
                class="sidebar-element">
                <a href="/admin" class="sidebar-element-link">
                    <div>
                        <a href="/admin" class="sidebar-element-row sidebar-element-link">
                            <!--                        <router-link to="/admin" class="sidebar-element-row sidebar-element-link">-->
                            <span class="sidebar-element-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path class="svg-fill"
                                          d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/>
                                </svg>
                            </span>
                            <span class="sidebar-element-name in-expanded-sidebar ap2-truncate"
                                  @click="openConfirmationModal($event, '/admin')">
                                Admin
                            </span>
                        </a>
                        <!--                        </router-link>-->
                    </div>
                </a>
            </li>
        </ul>
        <div :class="['user-sidebar', isLocalMode ? 'bg-local' : 'bg-prod']">
            <div class="user-avatar"><span class="user-avatar-circle" style="background-color: #1c5b73"><span
                class="user-avatar-initials">{{ getUsernameInitials }}</span></span></div>
            <div class="user-details in-expanded-sidebar justify-content-between">
                <div class="user-info-wrapper">
                    <div class="user-name ap2-truncate">{{ getCompleteUsername }}</div>
                    <div class="user-mail ap2-truncate">{{ getUser ? getUser.email : '' }}</div>
                </div>
                <div class="btn-group" role="group">
                    <button
                        id="btnGroupDrop1"
                        type="button"
                        class="card-rounded-button options"
                        :style="{'-webkit-mask-image': 'url(' + optionsImage + ')'}"
                        tooltip="Options"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    />
                    <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                        <li @click="openUserModal(getUser.id)">
                            <button class="dropdown-item" type="button" data-bs-toggle="modal"
                                    data-bs-target="#user-modal">
                                <i class="fas fa-user me-2"/>
                                Profile
                            </button>
                        </li>
                        <!--                        <li @click="openResetPasswordModal(getUser.id)">-->
                        <!--                            <button class="dropdown-item" type="button" data-bs-toggle="modal"-->
                        <!--                                    data-bs-target="#user-modal">-->
                        <!--                                <i class="fas fa-key me-2"/>-->
                        <!--                                Reset password-->
                        <!--                            </button>-->
                        <!--                        </li>-->
                        <li @click="logout()">
                            <button class="dropdown-item" type="button">
                                <i class="fas fa-power-off me-2"/>
                                Logout
                            </button>
                        </li>
                    </ul>
                </div>

                <!--                <div class="options" ></div>-->
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {isAdmin, isSystem} from "@/helpers/user-roles";
import {getLoggedUser, logout} from "@/services/session";
import logo from '@/assets/images/the-platform-logo-icon.svg'
import optionsImage from '@/assets/images/ellipsis-vertical-solid.svg'
import {getCookie} from "@/helpers/get-cookie";
import _ from "lodash";

export default {
    name: 'Sidebar',
    created() {
        this.loadUser();
    },
    data() {
        return {
            getManagementOpen: false,
            getSystemOpen: false,
            getInsightsOpen: false,
            logo: logo,
            optionsImage: optionsImage
        }
    },
    computed: {
        ...mapGetters(['getSidebarOpen', 'getUser', 'getModalUser', 'getConstants', 'getModalConfirmation', 'getCurrentObject', 'getIsObjectChanged']),
        isAdminUser() {
            return isAdmin(this.getUser);
        },
        isLocalMode() {
            return _.includes(location.host, 'localhost');
        },
        isSystemUser() {
            return isSystem(this.getUser);
        },
        getCompleteUsername() {
            return this.getUser.label_name
        },
        getUsernameInitials() {
            return this.getUser.first_name[0] + this.getUser.last_name[0]
        },
        currentRouteName() {
            return this.$route.name;
        },
    },
    watch: {
        currentRouteName: {
            deep: true,
            handler: function (newVal) {
                this.closeSidebar()
            }

        }
    },
    methods: {
        openSidebar() {
            this.$store.dispatch('setSidebarOpen', {sidebarOpen: true});
        },
        closeSidebar() {
            this.$store.dispatch('setSidebarOpen', {sidebarOpen: false});
            this.getManagementOpen = false;
            this.getSystemOpen = false;
            this.getInsightsOpen = false;
        },
        setManagement() {
            this.getManagementOpen ? this.getManagementOpen = false : this.getManagementOpen = true
        },
        setInsights() {
            this.getInsightsOpen ? this.getInsightsOpen = false : this.getInsightsOpen = true
        },
        setSystem() {
            this.getSystemOpen ? this.getSystemOpen = false : this.getSystemOpen = true
        },
        openUserModal(id) {
            _.set(this.getModalUser, 'id', id);
            _.set(this.getModalUser, 'show', true);
            this.addQueryParam('userId', id);
        },
        async loadUser() {
            let response;
            try {
                response = await getLoggedUser();
            } catch (e) {
                console.log("Fail loading user...");
                return null;
            }

            this.$store.dispatch('setUser', {user: response.data.result});
        },
        async logout() {
            let response;
            try {
                const formData = new FormData();
                formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));
                response = await logout(formData);
            } catch (e) {
                console.log("Logout error: ", e);
                return null;
            }

            if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                this.$toast.open({
                    message: response.data.message,
                    type: 'error',
                    position: 'top-right',
                    duration: 10000,
                });
                return null;
            }

            window.location = '/login/'
        },
        openConfirmationModal(event, path) {
            if (this.getIsObjectChanged) {
                event.preventDefault();
                _.set(this.getModalConfirmation, 'origin', this.getCurrentObject.origin)
                _.set(this.getModalConfirmation, 'destiny', path)
                _.set(this.getModalConfirmation, 'show', true)
                _.set(this.getModalConfirmation, 'items', null)
                _.set(this.getModalConfirmation, 'action', 'change')
            }
        }
    }
}
</script>