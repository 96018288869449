<template>
    <div :class="['ap2-modal ap2-modal-xs ap2-modal-content-center ap2-modal-two-options', getModalConfirmationBackward.show ? 'open': null]"
         id="confirmation-modal">
        <div class="modal-wrapper ">
            <div class="ap2-modal-header">
                <div class="modal-header-title-wrapper">
                    <div class="modal-header-reference">
                       Datashare
                    </div>
                    <div class="modal-header-title text-capitalize">
                        Reset Backward
                    </div>
                </div>

                <div class="modal-header-close close-modal-demo">
                    <svg @click="setAction('cancel')"
                         height="1em"
                         viewBox="0 0 384 512"
                         xmlns="http://www.w3.org/2000/svg">
                        <path class="svg-fill"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                </div>
            </div>
            <div class="ap2-modal-content" id="modal-confirmation">
                <div class="warning-image"
                     :style="{'-webkit-mask': 'url(' + danger + ')', '-webkit-mask-repeat': 'no-repeat', '-webkit-mask-position': 'center'}"></div>
                <div class="modal-headline">
                    Are you sure?
                </div>
                <p class="modal-text"> You are about to reset the backward task for this datashare </p>
            </div>
            <div class="ap2-modal-footer modal-controls" style="z-index: 11;">
                <div class="modal-controls">
                    <button type="button" class="ap2-btn btn btn-dark close-modal-demo"
                            @click="setAction('cancel')">
                        Cancel
                    </button>
                    <button type="button" class="ap2-btn btn btn-primary close-modal-demo"
                            @click="setAction('accept')">
                        Yes, I'm sure
                    </button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import danger from "@/assets/images/triangle-exclamation-solid.svg"
import {getProblematicDataSharesEnabling} from "@/services/data-share";
import {getCookie} from "@/helpers/get-cookie";

export default {
    name: "modalConfirmationBackward",
    computed: {
        ...mapGetters(['getModalConfirmationBackward']),
    },
    data() {
        return {
            danger: danger,
        }
    },
    methods: {
        setAction(action) {
            _.set(this.getModalConfirmationBackward, 'result', action)
            _.set(this.getModalConfirmationBackward, 'show', false)
        },
    }
}
</script>

<style scoped>

</style>