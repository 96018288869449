<template>
    <div v-if="getModalUser.show">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                        <div v-if="!data" class="loader at-inner-loader" id="preload"></div>
                        <div v-if="data" class="modal-content">
                            <div v-if="saving" class="modal-overlay"></div>
                            <div :class="['modal-header', saving ? 'modal-wt-pointer' : null]">
                                <div class="at-breadcrumb"
                                     style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);">
                                    <ol class="breadcrumb ">
                                        <li class="breadcrumb-item">
                                            <a>Users</a>
                                        </li>
                                    </ol>
                                    <h2>{{ data.username ? data.username : 'User' }}</h2>
                                </div>
                                <div class="at-nav-controls">
                                    <button
                                            @click="close()"
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                    ></button>
                                </div>
                            </div>
                            <div :class="['modal-body p-0', saving ? 'modal-wt-pointer' : null]">
                                <form id="user-form" @submit.prevent="send()">
                                    <div class="container p-3">
                                        <div class="row">
                                            <div class="col col-sm-4">
                                                <label for="modal-username-input" class="form-label">User name</label>
                                                <div class="input-group mb-3">
                                                    <input
                                                            @input="data.username = $event.target.value"
                                                            v-model.trim="v$.data.username.$model"
                                                            type="text"
                                                            class="form-control"
                                                            id="modal-username-input"
                                                            name="user_form[username]"
                                                            aria-describedby="basic-addon3"
                                                            :disabled="isProfileMode()"
                                                    >
                                                </div>
                                                <small class="form-text ml-1 error"
                                                       v-if="submitted && v$.data.username.required.$invalid">
                                                    <span class="text-danger">
                                                        Username is mandatory.
                                                    </span>
                                                </small>
                                            </div>
                                            <div class="col col-sm-4">
                                                <label for="modal-first-name-input" class="form-label">First
                                                    name</label>
                                                <div class="input-group mb-3">
                                                    <input
                                                            @input="data['first_name'] = $event.target.value"
                                                            v-model.trim="v$.data['first_name'].$model"
                                                            type="text"
                                                            class="form-control"
                                                            id="modal-first-name-input"
                                                            name="user_form[first_name]"
                                                            aria-describedby="basic-addon3"
                                                    >
                                                </div>
                                                <small class="form-text ml-1 error"
                                                       v-if="submitted && v$.data['first_name'].required.$invalid">
                                                    <span class="text-danger">
                                                        First name is mandatory.
                                                    </span>
                                                </small>
                                            </div>
                                            <div class="col col-sm-4">
                                                <label for="modal-lastname-input" class="form-label">Last name</label>
                                                <div class="input-group mb-3">
                                                    <input
                                                            @input="data['last_name'] = $event.target.value"
                                                            v-model.trim="v$.data['last_name'].$model"
                                                            type="text"
                                                            class="form-control"
                                                            id="modal-lastname-input"
                                                            name="user_form[last_name]"
                                                            aria-describedby="basic-addon3"
                                                    >
                                                </div>
                                                <small class="form-text ml-1 error"
                                                       v-if="submitted && v$.data['last_name'].required.$invalid">
                                                    <span class="text-danger">
                                                        Last name is mandatory.
                                                    </span>
                                                </small>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col col-sm-8">
                                                <label for="modal-user-email-input" class="form-label">Email</label>
                                                <div class="input-group mb-3">
                                                    <input
                                                            @input="data.email = $event.target.value"
                                                            v-model.trim="v$.data.email.$model"
                                                            type="text"
                                                            class="form-control"
                                                            id="modal-user-email-input"
                                                            name="user_form[email]"
                                                            aria-describedby="basic-addon3"
                                                            :disabled="isProfileMode()"
                                                    >
                                                </div>
                                                <small class="form-text ml-1 error"
                                                       v-if="submitted && v$.data.email.required.$invalid">
                                                    <span class="text-danger">
                                                        User email is mandatory.
                                                    </span>
                                                </small>
                                                <small class="form-text ml-1 error"
                                                       v-if="submitted && v$.data.email.email.$invalid">
                                                    <span class="text-danger">
                                                        User email must have a correct email format.
                                                    </span>
                                                </small>
                                            </div>
                                            <div class="col col-sm-4">
                                                <label for="" class="form-label">Role</label>
                                                <div class="input-group mb-3">
                                                    <div class="btn-group w-100" role="group">
                                                        <button
                                                                id="role-selector"
                                                                type="button"
                                                                class="btn btn-outline-secondary dropdown-toggle text-end at-dropdown-toggle"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                :disabled="isProfileMode()"
                                                        >
                                                            <span :class="['badge rounded-pill ', getRoleSelected.class]">{{
                                                                    getRoleSelected.name
                                                                }}</span>
                                                        </button>
                                                        <div
                                                                tabindex="-1"
                                                                role="menu"
                                                                aria-hidden="true"
                                                                class="at-dropdown-menu  dropdown-menu dropdown-menu-end"
                                                                style="z-index:9;"
                                                        >
                                                            <div class="scrollbar-container ps ">
                                                                <section class="at-dropdown-items_listWrap">
                                                                    <ul class="nav flex-column">
                                                                        <li
                                                                                @click="selectRole(role.name)"
                                                                                v-for="role in getRoleOptions"
                                                                                :key="role.name"
                                                                                class=""

                                                                        >
                                                                            <a :class="['dropdown-item', role.name === this.data.role ? 'active' : null]">
                                                                            <span class="at-dropdown-items-item">
                                                                                <span :class="['badge rounded-pill', role.class]">
                                                                                    {{ role.name }}
                                                                                </span>
                                                                            </span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
<!--                                        <div v-if="!data.id" class="row">-->
<!--                                            <div class="col col-sm-4">-->
<!--                                                <label for="modal-user-email-input" class="form-label">Password</label>-->
<!--                                                <div class="input-group mb-3">-->
<!--                                                    <input-->
<!--                                                            @input="data.password = $event.target.value"-->
<!--                                                            :value="data.password"-->
<!--                                                            type="password"-->
<!--                                                            class="form-control"-->
<!--                                                            id="modal-user-pasword-input"-->
<!--                                                            name="user_form[password]"-->
<!--                                                            aria-describedby="basic-addon3"-->
<!--                                                    >-->
<!--                                                </div>-->
<!--                                                <small class="form-text ml-1 error"-->
<!--                                                       v-if="submitted && !isPasswordValueValid()">-->
<!--                                                    <span class="text-danger">-->
<!--                                                        User password is mandatory.-->
<!--                                                    </span>-->
<!--                                                </small>-->
<!--                                            </div>-->
<!--                                            <div class="col col-sm-4 ">-->
<!--                                                <label for="" class="form-label">Confirm new password</label>-->
<!--                                                <div class="input-group mb-3">-->
<!--                                                    <input-->
<!--                                                            @input="confirmationPassword = $event.target.value"-->
<!--                                                            :value="confirmationPassword"-->
<!--                                                            type="password"-->
<!--                                                            class="form-control"-->
<!--                                                            id="modal-user-confirmation-password-input"-->
<!--                                                            name="user_form[confirmation_password]"-->
<!--                                                            aria-describedby="basic-addon3"-->
<!--                                                    >-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                            <div v-if="data.password !== '' " class="col d-flex align-items-center">-->
<!--                                                <label for="" class="form-label">Password Check</label>-->
<!--                                                <div v-if="!isPasswordConfirmationEqual()"-->
<!--                                                     class="d-flex align-items-center ms-2">-->
<!--                                                    <i class="fa fa-times-circle" style="color: red"></i>-->
<!--                                                </div>-->
<!--                                                <div v-if="isPasswordConfirmationEqual()"-->
<!--                                                     class="d-flex align-items-center ms-2">-->
<!--                                                    <i class="fa fa-check-circle" style="color: green"></i>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
                                    </div>
                                    <div class="modal-header sticky-top">
                                        <h6 data-bs-toggle="collapse" data-bs-target="#user-teams-collapse">Teams</h6>
                                        <div class="at-nav-controls">
                                            <button
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#user-teams-collapse"
                                                    role="button"
                                                    class="btn"
                                                    aria-expanded="false"
                                                    aria-controls="collapseExample"
                                            >
                                                <i class="fas fa-chevron-down"/>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="container mt-3 collapse show" id="user-teams-collapse">
                                        <div class="row pb-2">
                                            <div class="col-sm-12 offset-sm-0 col-md-6 offset-md-6">
                                                <div class="input-group me-2">
                                                    <input
                                                            :value="searchTeam"
                                                            @input="searchTeam = $event.target.value"
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Search teams"
                                                    >
                                                    <button v-if="searchTeam === ''"
                                                            class="btn btn-outline-secondary at-icon-search-sidebar"
                                                            type="button"
                                                            id="button-addon2"
                                                    >
                                                        <i class="fas fa-search"/>
                                                    </button>
                                                    <button @click="eraseTeamSearch"
                                                            type="button"
                                                            class="btn btn-outline-secondary at-restore-search-sidebar"
                                                            id="button-addon3"
                                                            v-else>
                                                        <i class="fas fa-times"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="at-user-client-container mt-4 pb-4">
                                            <div v-if="getTeamsFiltered.length > 0">
                                                <user-team
                                                        v-for="team in getTeamsFiltered"
                                                        @dispatch-items-selected="selectTeams($event)"
                                                        :key="team.id"
                                                        :team="team"
                                                        :items-selected="teamsSelected"
                                                />
                                            </div>
                                            <div v-else>
                                                <span class="text-center text-muted">No teams</span>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col">
                                                <button
                                                        @click="deleteTeams"
                                                        class="btn btn-outline-secondary"
                                                        :disabled="teamsSelected.length===0 || isProfileMode()"
                                                        type="button"
                                                >
                                                    Delete teams
                                                </button>
                                            </div>
                                            <div class="col ">
                                                <div class="col ">
                                                    <div
                                                            class="btn-group float-end"
                                                            role="group"
                                                            aria-label="Button group with nested dropdown"
                                                    >
                                                        <div class="btn-group" role="group">
                                                            <button
                                                                    id="client-selector"
                                                                    type="button"
                                                                    class="btn btn-outline-secondary dropdown-toggle text-end at-dropdown-toggle"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                    style="min-width:200px"
                                                                    :disabled="isProfileMode()"
                                                            >
                                                                {{ teamSelected ? teamSelected.name : 'Team name' }}
                                                            </button>
                                                            <div
                                                                    tabindex="-1"
                                                                    role="menu"
                                                                    aria-hidden="true"
                                                                    class="at-dropdown-menu dropdown-menu dropdown-menu-end"
                                                                    style="z-index:9;"

                                                            >
                                                                <div class="input-group at-dropdown-menu-search">
                                                                    <input
                                                                            :value="searchTeamOption"
                                                                            @input="searchTeamOption = $event.target.value"
                                                                            type="text"
                                                                            class="form-control"
                                                                            placeholder="Search"
                                                                            aria-label="Recipient's username"
                                                                            aria-describedby="basic-addon2"
                                                                    >
                                                                    <div v-if="searchTeamOption === ''"
                                                                         class="input-group-append">
                                                                        <span class="input-group-text">
                                                                            <i class="fas fa-search"/>
                                                                        </span>
                                                                    </div>
                                                                    <div v-else
                                                                         @click="eraseTeamOptionSearch"
                                                                         class="input-group-append"
                                                                         id="eraseTeamOption"
                                                                    >
                                                                        <span class="input-group-text">
                                                                            <i class="fas fa-times"/>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="scrollbar-container ps ">
                                                                    <section class="at-dropdown-items_listWrap">
                                                                        <div class="col-xs-12">
                                                                            <div class="divider row"></div>
                                                                        </div>
                                                                        <ul v-if="getTeamOptions.length > 0"
                                                                            class="nav flex-column">
                                                                            <li v-for="team in getTeamOptions"
                                                                                @click="teamSelected = team"
                                                                                :key="team.id"
                                                                                class=""
                                                                            >
                                                                                <a class="dropdown-item">
                                                                                <span class="at-dropdown-items-item">
                                                                                    {{ team.name }}
                                                                                </span>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                        <div class="text-center" v-else>
                                                                            <span class="text-muted">
                                                                                    No teams
                                                                            </span>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button
                                                                @click="addTeam(teamSelected)"
                                                                id="team-add"
                                                                type="button"
                                                                 :class="['btn btn-outline-success text-nowrap', (!teamSelected || isProfileMode()) ? 'at-disabled': null]"
                                                                aria-expanded="false"
                                                        >
                                                            <i class="fas fa-plus me-1"/>
                                                            Add team
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div :class="['modal-footer', saving ? 'modal-wt-pointer' : null]">
                                <button
                                        @click="close()"
                                        type="button"
                                        class="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                        type="submit"
                                        class="btn btn-primary"
                                        form="user-form"
                                >
                                    <i class="fas fa-save me-2"/>
                                    Save changes
                                </button>
                            </div>
                            <div v-if="saving">
                                <div class="loader at-inner-loader" id="preload"></div>
                                <span class="loading" style="">Saving changes</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>

    import {detail, store, edit} from "@/services/user";
    import {list as TeamList} from "@/services/team";
    import _ from 'lodash';
    import {mapGetters} from "vuex";
    import UserTeam from "@/components/modal/modal-user/team";
    import useVuelidate from '@vuelidate/core';
    import {required, email} from '@vuelidate/validators'
    import {getCookie} from "@/helpers/get-cookie";
    import {isUser} from "@/helpers/user-roles";

    export default {
        name: 'ModalUser',
        setup: () => ({
            v$: useVuelidate()
        }),
        components: {
            UserTeam
        },
        data() {
            return {
                id: null,
                data: null,
                teamOptions: [],
                roleOptions: [
                    {'name': 'User', 'class': 'bg-success'},
                    {'name': 'Admin', 'class': 'bg-info'},
                    {'name': 'System', 'class': 'bg-dark'},
                ],
                includeRoleOptions: {
                    User: [],
                    Admin: [],
                    System: [],
                },
                submitted: false,
                searchTeam: '',
                searchTeamOption: '',
                teamsSelected: [],
                teamSelected: null,
                confirmationPassword: null,
                saving: false
            }
        },
        validations() {
            return {
                data: {
                    username: {
                        required,
                    },
                    email: {
                        required, email
                    },
                    first_name: {
                        required
                    },
                    last_name: {
                        required
                    },
                },
            }
        },
        created() {
            this.includeRoleOptions.Admin = [this.getConstants.USER_ROLES.ROLE_USER];
            this.includeRoleOptions.System = [this.getConstants.USER_ROLES.ROLE_USER, this.getConstants.USER_ROLES.ROLE_ADMIN, this.getConstants.USER_ROLES.ROLE_SYSTEM];
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    this.close();
                }
            });
        },
        computed: {
            ...mapGetters(['getModalUser', 'getUser', 'getConstants']),
            getRoleSelected() {
                if (this.data) {
                    let role = _.find(this.roleOptions, role => role.name === this.data.role);
                    if (role !== undefined) {
                        return role;
                    }
                }
                return this.roleOptions[0];
            },
            getTeamsFiltered() {
                if (this.searchTeam === '') {
                    return this.data.teams;
                }
                return _.filter(this.data.teams, team => _.toLower(team.name).includes(_.toLower(this.searchTeam)));
            },
            getTeamIdsByUser() {
                if (this.data) {
                    let ids = [];
                    _.forEach(this.data.teams, team => {
                        ids.push(team.id);
                    });
                    return ids;
                }
                return [];
            },
            getTeamOptions() {
                let teamOptions = _.filter(this.teamOptions, team => !_.includes(this.getTeamIdsByUser, team.id));
                if (this.searchTeamOption === '') {
                    return teamOptions;
                }
                return _.filter(teamOptions, team => _.toLower(team.name).includes(_.toLower(this.searchTeamOption)));
            },
            getRoleOptions(){
                return _.filter(this.roleOptions, role => _.includes(this.includeRoleOptions[this.getUser.role], role.name));
            }
        },
        mounted() {
            this.$store.watch(state => state.modalUser, (value) => {
                if (value.show) {
                    this.id = value.id;
                    this.loadUser();
                    this.loadTeams();
                }
            }, {deep: true});
        },
        methods: {
            async loadUser() {
                let response;
                try {
                    response = await detail(this.id);
                } catch (e) {
                    return;
                }

                if (this.id !== null && response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: 'Error recovering user details',
                        type: 'error',
                        position: 'top-right',
                        duration: 2000,
                    });
                    return;
                }

                this.data = response.data.result;
            },
            async loadTeams() {
                let response;
                try {
                    response = await TeamList();
                } catch (e) {
                    return;
                }

                this.teamOptions = response.data.result;
            },
            addTeam(newTeam) {
                this.data.teams.push(newTeam);
                this.teamSelected = null;
                this.eraseTeamOptionSearch();
            },
            selectTeams(selected) {
                this.teamsSelected = selected;
            },
            deleteTeams() {
                this.data.teams = _.filter(this.data.teams, team => !_.includes(this.teamsSelected, team.id));
                this.teamsSelected = [];
            },
            send() {
                let validations = [];
                validations.push(this.validateForm());
                // if (!this.data.id) {
                //     validations.push(this.isPasswordValueValid());
                //     validations.push(this.isPasswordConfirmationEqual());
                // }

                if (!_.includes(validations, false)) {
                    this.save();
                }
            },
            async save() {
                this.saving = true;
                let response;
                try {
                    let formElement = document.querySelector('#user-form');
                    let formData = new FormData(formElement);
                    formData.append('id', this.id);
                    formData.append('username', this.data.username);
                    formData.append('first_name', this.data.first_name);
                    formData.append('last_name', this.data.last_name);
                    formData.append('email', this.data.email);
                    formData.append('role', this.data.role);
                    formData.append('teams', JSON.stringify(this.getTeamIdsByUser));
                    formData.append('csrfmiddlewaretoken', getCookie('csrftoken'));

                    // if (!this.id) {
                    //     formData.append('password', this.data.password);
                    // }

                    response = this.id === null ? await store(formData) : await edit(formData);
                } catch (e) {
                    console.error("[ERROR]", e);
                    this.$toast.open({
                        message: "Failed applying the changes",
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                if (response.data.status === this.getConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 3000,
                    });
                    return;
                }

                _.set(this.getModalUser, 'result', response.data.result);
                this.close();
                this.saving = false;

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 3000,
                });
            },
            selectRole(role) {
                this.data.role = role;
            },
            eraseTeamSearch() {
                this.searchTeam = '';
            },
            eraseTeamOptionSearch() {
                this.searchTeamOption = '';
            },
            close() {
                this.reset();
                _.set(this.getModalUser, 'id', null);
                _.set(this.getModalUser, 'show', false);
                this.deleteQueryParam('userId');
                this.confirmationPassword = null;
            },
            reset() {
                this.data = null;
                this.teamOptions = []
                this.submitted = false;
            },
            validateForm() {
                this.submitted = true;
                this.v$.$touch();
                return !this.v$.$invalid;
            },
            deleteQueryParam(param) {
                const query = Object.assign({}, this.$route.query);
                delete query[param];
                this.$router.replace({query});
            },
            isPasswordValueValid() {
                return this.data.password !== ''
            },
            isPasswordConfirmationEqual() {
                return this.data.password === this.confirmationPassword && this.data.password !== '';
            },
            isProfileMode(){
                if (this.data.id && this.data.id === this.getUser.id && isUser(this.getUser)){
                    return true;
                }
                return false;
            }
        }
    }
</script>
