<template>
    <div @click="selectType" :class="['ap2-card app-card-marketplace platforms Enabled']">
        <div :class="['card-wrapper Enabled']">
            <div class="card-content">
                <div :class="['rounded-label-platforms', type.name]">
                    <span></span>
                </div>
            </div>
            <div class="card-info">
                <div class="app-card-name ap2-truncate platforms-text">
                    {{ type.label }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {isSystem} from "@/helpers/user-roles";


export default {
    name: "TypeCard",
    props: {
        type: {
            type: Object,
        }
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['getConstants', 'getUser']),
        isSystemUser() {
            return isSystem(this.getUser);
        },
    },
    methods: {
        selectType() {
            this.$emit('selection', this.type);
            console.log('fdfd', this.type)
        },
        // isCardDisabled() {
        //     if (this.type.name === 'google-sheets') {
        //         return !(this.type.status === 'Enabled' && this.isSystemUser)
        //     } else {
        //         return false
        //     }
        // }

    }
}
</script>

<style scoped>

</style>