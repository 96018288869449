import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 */
export function list() {
    const params = {};
    return AxiosWrapper('/insights/field_scopes/list/', {params});
}

export function getFieldsByScope(scopeId = null) {
    const params = {scopeId: scopeId};
    return AxiosWrapper('/insights/field_scopes/fields_by_scope_list/', {params});
}


/**
 * @returns Promise
 */

export function getFieldsByAccount(formData = null) {
    return AxiosWrapper('/insights/field_scopes/fields_by_account_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}


/**
 * @returns Promise
 */
export function getMetrics() {
    const params = {};
    return AxiosWrapper('/insights/field_scopes/metrics_list/', {params});
}

/**
 * @returns Promise
 */
export function getStandardMetrics() {
    const params = {};
    return AxiosWrapper('/insights/field_scopes/standard_metrics/', {params});
}


/**
 * @returns Promise
 */
export function getMetricsByPlatform(platform) {
    const params = {platform: platform};
    return AxiosWrapper('/insights/field_scopes/metrics_list/', {params});
}


/**
 * @returns Promise
 */
export function getFieldsDependenciesForField(fieldId) {
    const params = {fieldId: fieldId};
    return AxiosWrapper('/insights/fields/field_dependencies/', {params});
}


/**
 * @returns Promise
 */
export function getDimensions() {
    const params = {};
    return AxiosWrapper('/insights/field_scopes/dimensions_list/', {params});
}


/**
 * @returns Promise
 */
export function getDimensionsByPlatform(platform) {
    const params = {platform: platform};
    return AxiosWrapper('/insights/field_scopes/dimensions_list/', {params});
}
