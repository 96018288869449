<template>
    <DetailHeader
            v-if="$route.name === 'DataSharesEdit' || $route.name === 'GoogleLookerStudioDataShareNew' || $route.name === 'GoogleSheetsDataShareNew' || $route.name === 'GoogleLookerStudioDataShareEdit' || $route.name === 'GoogleSheetsDataShareEdit'"
            :route-to-duplicate="getRouteToDuplicate">
        <template v-slot:breadcrumb>
            <div id="breadcrumb">
                <ul>
                    <li>
                        <a href="/data-shares" class="navigate-router">
                            <span class="breadcrumb-level-1 navigate-to ap2-truncate"
                                  @click="openConfirmationModal($event)">
                                Data shares
                            </span>
                        </a>
                        <ul>
                            <li>
                                <span v-if="getMainSubsectionName"
                                      class="breadcrumb-level-2 ap2-truncate">
                                    {{ getMainSubsectionName }}
                                </span>
                            </li>
                        </ul>
                        <img :src="chevronDownSolid" class="breadcrumb-chevron">
                    </li>
                    <li>
                        <span class="breadcrumb-level-1 ap2-truncate">
                            {{ getSecondarySectionName }}
                        </span>
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:actions>
            <div v-if="getUser && isSystemUser"
                 class="rounded-control-header  " tooltip="Options" role="group">
                <button
                        id="btnAppInClientAccountOptions"
                        type="button"
                        class="options"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        :style="{'-webkit-mask-image': 'url(' + optionsImage + ')', 'webkit-mask-size': '6px'}"
                />
                <ul class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="btnAppInClientAccountOptions"
                >
                    <li>
                        <a class="dropdown-item"
                           @click="openBackwardConfirmationModal()">
                            <i class="fas fa-play me-2" style="color: #499C54"/>
                            Reset Backward
                        </a>
                    </li>
                    <li v-if="$route.name === 'GoogleSheetsDataShareEdit'">
                        <a class="dropdown-item"
                           @click="executeDump()">
                            <i class="fas fa-play me-2" style="color: #499C54"/>
                            Execute Dump
                        </a>
                    </li>
                </ul>
            </div>
        </template>
    </DetailHeader>
</template>

<script>
import DetailHeader from "@/components/commons/header/detail-header";
import chevronDownSolid from "@/assets/images/chevron-down-solid.svg";
import {mapGetters} from "vuex";
import _ from "lodash";
import {isSystem} from "@/helpers/user-roles";
import optionsImage from "@/assets/images/ellipsis-vertical-solid.svg";
import {executeDump} from "@/services/google-sheets-data-share";

export default {
    name: "DataShareDetailHeader",
    components: {DetailHeader},
    data() {
        return {
            chevronDownSolid: chevronDownSolid,
            optionsImage: optionsImage
        }
    },
    computed: {
        ...mapGetters(['getModalConfirmation', 'getIsObjectChanged', 'getCurrentObject', 'getUser', 'getInsightsConstants', 'getModalConfirmationBackward']),
        isSystemUser() {
            return isSystem(this.getUser);
        },
        getMainSubsectionName() {
            let subsectionName = null;
            if (this.getCurrentObject.object) {
                if (this.getCurrentObject.object.id) {
                    subsectionName = this.getCurrentObject.object.name;
                }
            }
            return subsectionName;
        },
        getSecondarySectionName() {
            let sectionName = null;
            if (this.$route.name.includes('New')) {
                return 'New'
            }
            if (this.$route.name.includes('Edit')) {
                return 'Edit'
            }
            return sectionName
        },
        getRouteToDuplicate() {
            let routeToDuplicate = null;
            if ((this.$route.name === 'GoogleLookerStudioDataShareEdit' || this.$route.name === 'GoogleSheetsDataShareEdit') && this.getCurrentObject.object) {
                if (this.getCurrentObject.object.id) {
                    routeToDuplicate = '/data-shares/' + this.getCurrentObject.object.type.name + '/new?originalDataShareId=' + this.getCurrentObject.object.id;
                }
            }
            return routeToDuplicate
        }
    },
    methods: {
        async executeDump() {
            let response;

            try {
                response = await executeDump(this.getCurrentObject.object.id);
            } catch (e) {
                return e;
            }

            if (response.data.status === this.getInsightsConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                this.$toast.open({
                    message: response.data.message,
                    type: 'error',
                    position: 'top-right',
                    duration: 5000,
                });
                return;
            }

            this.$toast.open({
                message: response.data.message,
                type: 'success',
                position: 'top-right',
                duration: 5000,
            });
        },
        openConfirmationModal(event) {
            if (this.getIsObjectChanged) {
                event.preventDefault();
                _.set(this.getModalConfirmation, 'origin', 'data-share')
                _.set(this.getModalConfirmation, 'show', true)
                _.set(this.getModalConfirmation, 'items', null)
                _.set(this.getModalConfirmation, 'action', 'change')
            }
        },
        openBackwardConfirmationModal() {
            _.set(this.getModalConfirmationBackward, 'show', true)
        },
    }
}
</script>

<style scoped>

</style>