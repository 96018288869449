import store from "@/store"

export function isAdmin(user) {
    return user && user.role === store.state.constants.USER_ROLES.ROLE_ADMIN;
}

export function isSystem(user) {
    return user && user.role === store.state.constants.USER_ROLES.ROLE_SYSTEM;
}

export function isUser(user) {
    return user && user.role === store.state.constants.USER_ROLES.ROLE_USER;
}