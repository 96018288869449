<template>
    <DetailHeader
            v-if="($route.name === 'AppInClientNew' || $route.name === 'AppInClientEdit') && getCurrentClient && getCurrentObject.object"
            :route-to-duplicate="getRouteToDuplicate">
        <template v-slot:breadcrumb>
            <div id="breadcrumb">
                <ul>
                    <li>
                        <a href="/clients" class="navigate-router">
                            <!--                        <router-link :to="'/clients'" class="navigate-router">-->
                            <span class="breadcrumb-level-1 navigate-to ap2-truncate"
                                  @click="openConfirmationModal($event)">
                                Clients
                            </span>
                        </a>
                        <!--                        </router-link>-->
                        <ul>
                            <li>

                                    <span v-if="getMainSubsectionName"
                                          class="breadcrumb-level-2 ap2-truncate">
                                        <a :href="'/clients/edit?clientId=' + getCurrentClient.id"
                                           class="navigate-router">
                                            {{ getMainSubsectionName }}
                                        </a>
                                    </span>
                            </li>
                        </ul>
                        <img :src="chevronDownSolid" class="breadcrumb-chevron">
                    </li>
                    <li>
                        <a :href="'/apps-in-client?clientId=' + getCurrentClient.id"
                           class="navigate-router">
                            <!--                        <router-link :to="{name: 'AppsInClient', query: {clientId: getCurrentClient.id}}"-->
                            <!--                                     class="navigate-router">-->
                            <span class="breadcrumb-level-1 navigate-to ap2-truncate"
                                  @click="openConfirmationModal($event)">
                                Apps
                            </span>
                        </a>
                        <!--                        </router-link>-->
                        <ul>
                            <li>
                                <span
                                        class="breadcrumb-level-2 ap2-truncate">
                                    {{ getCurrentObject.object.app.name }}
                                </span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:actions>
            <div class="rounded-control-header  " tooltip="Options" role="group">
                <button
                        id="btnAppInClientAccountOptions"
                        type="button"
                        class="options"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        :style="{'-webkit-mask-image': 'url(' + optionsImage + ')', 'webkit-mask-size': '6px'}"
                />
                <ul class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="btnAppInClientAccountOptions"
                >
                    <li v-if="isSystemUser">
                        <a :class="['dropdown-item', ($route.name === 'AppInClientNew' || !this.getCurrentObject.object.app.is_debuggable) ? 'at-execute-disabled': null]"
                           @click="executeDebug()">
                            <i class="fas fa-bug" style="color: #499C54"/>
                            Execute debug
                        </a>
                    </li>
                    <li>
                        <a :class="['dropdown-item', $route.name === 'AppInClientNew' ? 'at-execute-disabled': null]"
                           @click="execute()">
                            <i class="fas fa-bolt me-2"/>
                            Execute
                        </a>
                    </li>
                </ul>

            </div>

            <!--            <div class="btn-group ms-2" role="group">-->
            <!--                <button-->
            <!--                        id="btnGroupDropAppsHeader"-->
            <!--                        type="button"-->
            <!--                        class="card-rounded-button options"-->
            <!--                        :style="['background-image: url(' + optionsImage + ')', 'height: 20px', 'background-repeat: no-repeat',-->
            <!--                        'border: none']"-->
            <!--                        tooltip="Options"-->
            <!--                        data-bs-toggle="dropdown"-->
            <!--                        aria-expanded="false"-->
            <!--                />-->
            <!--                <ul class="dropdown-menu" aria-labelledby="btnGroupDropAppsHeader">-->
            <!--                    <li>-->
            <!--                        <button class="dropdown-item" type="button" data-bs-toggle="modal"-->
            <!--                                data-bs-target="#user-modal">-->
            <!--                            <i class="fas fa-user me-2"/>-->
            <!--                            Profile-->
            <!--                        </button>-->
            <!--                    </li>-->
            <!--                </ul>-->
            <!--            </div>-->
            <!--            <div class="filter-menu" >-->
            <!--&lt;!&ndash;                <img :src="ellipsisVertical">&ndash;&gt;-->
            <!--                <ul class="filter-pills-wrapper" aria-labelledby="btnGroupDrop1">-->
            <!--                    <li>-->
            <!--                        <a :class="['dropdown-item', ($route.name === 'AppInClientNew' || !this.getCurrentObject.object.app.is_debuggable) ? 'at-execute-disabled': null]"-->
            <!--                           @click="executeDebug()">-->
            <!--                            <i class="fas fa-bug" style="color: #499C54"/>-->
            <!--                            Execute debug-->
            <!--                        </a>-->
            <!--                    </li>-->
            <!--                    <li>-->
            <!--                        <a :class="['dropdown-item', $route.name === 'AppInClientNew' ? 'at-execute-disabled': null]"-->
            <!--                           @click="execute()">-->
            <!--                            <i class="fas fa-bolt me-2"/>-->
            <!--                            Execute-->
            <!--                        </a>-->
            <!--                    </li>-->
            <!--                </ul>-->
            <!--            </div>-->
        </template>
    </DetailHeader>
</template>

<script>
    import DetailHeader from "@/components/commons/header/detail-header";
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import chevronDownSolid from "@/assets/images/chevron-down-solid.svg";
    import optionsImage from '@/assets/images/ellipsis-vertical-solid.svg'
    import {isSystem} from "@/helpers/user-roles";


    export default {
        name: "AppInClientDetailHeader",
        components: {DetailHeader},
        data() {
            return {
                chevronDownSolid: chevronDownSolid,
                optionsImage: optionsImage
            }
        },
        computed: {
            ...mapGetters(['getDetailAction', 'getModalConfirmation', 'getIsObjectChanged', 'getCurrentClient', 'getCurrentObject', 'getUser']),
            getMainSubsectionName() {
                let subsectionName = null;
                if (this.getCurrentClient) {
                    subsectionName = this.getCurrentClient.name;
                }
                return subsectionName;
            },
            isSystemUser() {
                return isSystem(this.getUser);
            },
            getRouteToDuplicate() {
                let routeToDuplicate = null;
                if (this.$route.name === 'AppInClientEdit' && this.getCurrentObject.object) {
                    if (this.getCurrentObject.object.id) {
                        routeToDuplicate = '/apps-in-client/new?originalAppInClientId=' + this.getCurrentObject.object.id;
                    }
                }
                return routeToDuplicate
            }
        },
        methods: {
            openConfirmationModal(event) {
                if (this.getIsObjectChanged) {
                    event.preventDefault();
                    _.set(this.getModalConfirmation, 'origin', 'apps-in-client')
                    _.set(this.getModalConfirmation, 'show', true)
                    _.set(this.getModalConfirmation, 'items', null)
                    _.set(this.getModalConfirmation, 'action', 'change')
                }
            },
            executeDebug() {
                _.set(this.getDetailAction, 'action', 'execute-debug')
            },
            execute() {
                _.set(this.getDetailAction, 'action', 'execute')
            }
        }
    }
</script>