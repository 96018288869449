import AxiosWrapper from '@/helpers/axios-wrapper'


/**
 * @returns Promise
 */
export function list(accountPlatform) {
    const params = {accountPlatform: accountPlatform};
    return AxiosWrapper('/clients/account/alerts/list/', {params});
}

/**
 * @returns Promise
 */
export function detail(appId) {
    const params = {appId: appId};
    return AxiosWrapper('/clients/account/alerts/detail/', {params});
}
