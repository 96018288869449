<template>
    <DetailHeader v-if="$route.name === 'FacebookDataSourceEdit' || $route.name === 'FacebookDataSourceNew'
        || $route.name === 'GoogleAdsDataSourceEdit' || $route.name === 'GoogleAdsDataSourceNew'"
                  :route-to-duplicate="getRouteToDuplicate">
        <template v-slot:breadcrumb>
            <div id="breadcrumb">
                <ul>
                    <li>
                        <a href="/data-sources" class="navigate-router">
                            <!--                        <router-link :to="'/users'" class="navigate-router">-->
                            <span class="breadcrumb-level-1 navigate-to ap2-truncate"
                                  @click="openConfirmationModal($event)">
                                Data sources
                            </span>
                        </a>
                        <!--                        </router-link>-->
                        <ul>
                            <li>
                                <span v-if="getMainSubsectionName"
                                      class="breadcrumb-level-2 ap2-truncate">
                                    {{ getMainSubsectionName }}
                                </span>
                            </li>
                        </ul>
                        <img :src="chevronDownSolid" class="breadcrumb-chevron">
                    </li>
                    <li>
                        <span class="breadcrumb-level-1 ap2-truncate">
                            {{ getSecondarySectionName }}
                        </span>
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:actions>
            <div v-if="getUser && isSystemUser" class="rounded-control-header  " tooltip="Options" role="group">
                <button
                        id="btnAppInClientAccountOptions"
                        type="button"
                        class="options"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        :style="{'-webkit-mask-image': 'url(' + optionsImage + ')', 'webkit-mask-size': '6px'}"
                />
                <ul class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="btnAppInClientAccountOptions"
                >
                    <li>
                        <a :class="['dropdown-item', newDatasourcesPaths.indexOf($route.name) !== -1 ? 'at-execute-disabled': null]"
                           @click="executeTask(getCustomConversionsTasks)">
                            <i class="fas fa-play me-2" style="color: #499C54"/>
                            Custom Conversions
                        </a>
                    </li>
                    <li v-if="facebookDatasourcesPaths.indexOf($route.name) !== -1">
                        <a :class="['dropdown-item', newDatasourcesPaths.indexOf($route.name) !== -1 ? 'at-execute-disabled': null]"
                           @click="executeTask('facebook_entities_recover')">
                            <i class="fas fa-play me-2" style="color: #499C54"/>
                            Entities
                        </a>
                    </li>
                    <li v-if="facebookDatasourcesPaths.indexOf($route.name) !== -1">
                        <a :class="['dropdown-item', newDatasourcesPaths.indexOf($route.name) !== -1 ? 'at-execute-disabled': null]"
                           @click="executeTask('currency_timezone')">
                            <i class="fas fa-play me-2" style="color: #499C54"/>
                            Currency Timezones
                        </a>
                    </li>
                </ul>
            </div>
        </template>
    </DetailHeader>
</template>

<script>
    import DetailHeader from "@/components/commons/header/detail-header";
    import chevronDownSolid from "@/assets/images/chevron-down-solid.svg";
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import optionsImage from "@/assets/images/ellipsis-vertical-solid.svg";
    import {isSystem} from "@/helpers/user-roles";
    import {execute} from "@/services/data-source";

    export default {
        name: "DataSourceDetailHeader",
        components: {DetailHeader},
        data() {
            return {
                chevronDownSolid: chevronDownSolid,
                optionsImage: optionsImage,
                newDatasourcesPaths:  ['GoogleAdsDataSourceNew', 'FacebookDataSourceNew'],
                facebookDatasourcesPaths: ['FacebookDataSourceNew', 'FacebookDataSourceEdit']
            }
        },
        computed: {
            ...mapGetters(['getInsightsConstants', 'getModalConfirmation', 'getIsObjectChanged', 'getCurrentObject', 'getUser']),
            isSystemUser() {
                return isSystem(this.getUser);
            },
            getCustomConversionsTasks(){
                return this.$route.name.includes('GoogleAds') ? 'google_custom_conversion_recover' : 'facebook_custom_conversion_recover'
            },
            getMainSubsectionName() {
                let subsectionName = null;
                if (this.getCurrentObject.object) {
                    if (this.getCurrentObject.object.id) {
                        subsectionName = this.getCurrentObject.object.name;
                    }
                }
                return subsectionName;
            },
            getSecondarySectionName() {
                let sectionName = null;
                if (this.$route.name.includes('New')) {
                    return 'New'
                }
                if (this.$route.name.includes('Edit')) {
                    return 'Edit'
                }
                return sectionName
            },
            getRouteToDuplicate() {
                let routeToDuplicate = null;
                if ((this.$route.name === 'FacebookDataSourceEdit' || this.$route.name === 'GoogleAdsDataSourceEdit') && (this.getCurrentObject.object !== null && this.getCurrentObject.object !== undefined)) {
                    if (this.getCurrentObject.object.id) {
                        routeToDuplicate = '/data-sources/' + this.getCurrentObject.object.platform.name + '/new?originalDataSourceId=' + this.getCurrentObject.object.id;
                    }
                }
                return routeToDuplicate
            }
        },
        methods: {
            async executeTask(task) {
                let response;

                try {
                    response = await execute(task, this.getCurrentObject.object.id);
                } catch (e) {
                    return e;
                }

                if (response.data.status === this.getInsightsConstants.RESPONSE_CODES.RESPONSE_CODE_ERROR) {
                    this.$toast.open({
                        message: response.data.message,
                        type: 'error',
                        position: 'top-right',
                        duration: 5000,
                    });
                    return;
                }

                this.$toast.open({
                    message: response.data.message,
                    type: 'success',
                    position: 'top-right',
                    duration: 5000,
                });
            },
            openConfirmationModal(event) {
                if (this.getIsObjectChanged) {
                    event.preventDefault();
                    _.set(this.getModalConfirmation, 'origin', 'data-source')
                    _.set(this.getModalConfirmation, 'show', true)
                    _.set(this.getModalConfirmation, 'items', null)
                    _.set(this.getModalConfirmation, 'action', 'change')
                }
            },
        }
    }
</script>

<style scoped>

</style>