<template>
    <div v-if="getUser" id="header" class="element">
        <slot name="breadcrumb"/>
        <div class="header-right">
            <button v-if="routeToDuplicate"
                    type="button"
                    class="ap2-btn btn btn-primary save-discard-buttons"
                    @click="goToDuplicateDetail()">
                Duplicate
            </button>
            <button v-if="hasDiscardSaveButtons()"
                    type="button"
                    class="ap2-btn btn btn-dark save-discard-buttons ms-5"
                    @click="discardButton">
                Discard
            </button>
            <button v-if="hasDiscardSaveButtons()"
                    type="button"
                    class="ap2-btn btn btn-success save-discard-buttons"
                    @click="saveButton">
                Save
            </button>
            <slot name="actions"/>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import {isSystem} from "@/helpers/user-roles";

export default {
    name: 'DetailHeader',
    computed: {
        ...mapGetters(['getDetailAction', 'getUser'])
    },
    props: {
        routeToDuplicate: {
            type: String,
            default: null
        }
    },
    methods: {
        saveButton() {
            _.set(this.getDetailAction, 'action', 'save')
        },
        hasDiscardSaveButtons() {
            if (!isSystem(this.getUser)) {
                return !(this.$route.name === 'DimensionsEdit' || this.$route.name === 'MetricsEdit')
            }
            return true
        },
        discardButton() {
            _.set(this.getDetailAction, 'action', 'discard')
        },
        goToDuplicateDetail() {
            window.location = this.routeToDuplicate;
        }
    }
}
</script>
